import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'

const date = new Date(new Date().getTime())

i18n
  .use(detector)
  .use(backend)
  .use(reactI18nextModule)
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: './locales/{{lng}}/{{ns}}.json',
      queryStringParams: {
        t: `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}`,
      },
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
      lookupLocalStorage: 'i18nextLng',
      lookupQuerystring: 'lang',
      caches: ['localStorage'],
    },
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
    react: {
      wait: true,
    },
  })

export default i18n
